<template>
  <div class="container ignore" v-scroll="onScroll">
    <div class="container-inner">
      <mosaic-grid :mosaics="mosaics" :is-loading="isLoading" />
    </div>

    <div class="container-inner" id="programma">
      <template v-if="!isLoading">
        <program-filters
          v-bind="filterOptions"
          @input="filters = $event"
          :value="filters"
        />
        <transition name="fade" mode="out-in">
          <program-list :items="programs" v-if="hasPrograms" />
          <p class="fs-large text-center" v-if="false">Geen resultaten</p>
        </transition>
      </template>
    </div>
    <div class="container-inner--nopadding brand-wrapper">
      <made-by />
    </div>
  </div>
</template>

<script>
import { Moment as moment } from "@/libs/utils/locale";
import markedBase from "marked";
import {
  isEmpty,
  groupBy,
  map,
  throttle,
  flatMap,
  filter,
  uniqBy,
  each,
  get,
  sortBy,
} from "lodash";
import MadeBy from "@/components/MadeBy";
import MosaicGrid from "@/components/MosaicGrid";
import ProgramList from "@/components/ProgramList";
import ProgramFilters from "@/components/ProgramFilters";
import { listAll as listAllMosaic } from "@/libs/apollo/queries/mosaic";
import { listAll as listAllThemes } from "@/libs/apollo/queries/theme";
import { listAll as listAllPerformances } from "@/libs/apollo/queries/performance";

const marked = (content) => content && markedBase(content);
const isNotEmpty = (value) => !isEmpty(value);

const optionsKey = {
  innerActValue: "name",
  innerDayValue: "weekDay",
  innerLocatieValue: "location",
  innerTypeValue: "genre",
};
const checkPerformence = (performance, filters) => {
  let newPerformence = [];
  const filtersValues = filters ? Object.values(filters) : [];
  const hasChecked = filtersValues.some(
    (e) => typeof e === "string" && e !== ""
  );
  if (!hasChecked) {
    newPerformence = performance;
  } else {
    newPerformence = performance.filter((e) => {
      return Object.keys(optionsKey).every((key) => {
        const optionValue = filters[key] || undefined;
        if (optionValue === undefined || optionValue === e[optionsKey[key]]) {
          return true;
        } else {
          return false;
        }
      });
    });
  }
  return newPerformence;
};
const getOptions = (
  performances,
  field,
  emptyText,
  emptyValue = "",
  sortFunc = null
) => {
  let options = uniqBy(performances, field).map((it) => ({
    key: it[field],
    text: it[field],
  }));
  if (sortFunc) {
    options = sortBy(options, sortFunc);
  } else {
    options = sortBy(options, (o) => o.key);
  }
  return [{ key: emptyValue, text: emptyText }, ...options];
};

const isFiltersEmpty = (filters) =>
  filters == null || Object.values(filters).every((v) => isEmpty(v));

export default {
  data() {
    return {
      fromThemePage: false,
      loading: 0,
      mosaics: [],
      performances: [],
      themes: [],
      filters: undefined,
      scrolledToProgrammas: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.scrollToElementIfNeeded({ delay: 500 });
      this.fromThemePage = "ftl" in this.$route.query;
    });
  },

  computed: {
    isLoading() {
      return this.loading > 0;
    },

    hasPrograms() {
      if (this.programs) {
        return Object.keys(this.programs).length > 0;
      }
      return false;
    },

    programs() {
      if (this.fromThemePage || !isFiltersEmpty(this.filters)) {
        // TODO: server side filtering?
        const items = this.filterPerformances();
        return groupBy(items, "weekDay");
      } else {
        return groupBy(this.performances, "weekDay");
      }
    },

    weekDayOptions() {
      const sortFunc = (it) => {
        const sortedDays =
          "maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag_zondag".split(
            "_"
          );
        return sortedDays.indexOf(it.text);
      };
      const performances = checkPerformence(this.performances, this.filters);
      return getOptions(performances, "weekDay", "DAG", "", sortFunc);
    },

    timeOptions() {
      // let performances = filter(this.filterPerformances(), it => {return !it.fullDay})
      return getOptions(this.performances, "startTime", "TIJD");
    },

    locationOptions() {
      const performances = checkPerformence(this.performances, this.filters);
      return getOptions(performances, "location", "LOCATIE");
    },

    genreOptions() {
      const performances = checkPerformence(this.performances, this.filters);
      return getOptions(performances, "genre", "GENRE");
    },

    nameOptions() {
      const performances = checkPerformence(this.performances, this.filters);
      return getOptions(performances, "name", "ACT");
    },

    filterOptions() {
      return {
        weekDayOptions: this.weekDayOptions,
        timeOptions: this.timeOptions,
        nameOptions: this.nameOptions,
        locationOptions: this.locationOptions,
        genreOptions: this.genreOptions,
      };
    },
  },

  methods: {
    onScroll: throttle(function () {
      this.$nextTick(() => {
        if (!this.scrolledToProgrammas) {
          return;
        }
        this.$router.hash = "";
        this.scrolledToProgrammas = false;
      });
    }, 500),

    scrollToElementIfNeeded({ delay }) {
      if (
        this.$route.hash &&
        this.$el.querySelector(this.$route.hash) != null
      ) {
        setTimeout(() => {
          this.$scrollTo(this.$route.hash, 500, {
            easing: "ease-in",
            offset: -200,
            onDone: () => {
              setTimeout(() => {
                this.scrolledToProgrammas = true;
              }, 100);
            },
          });
        }, delay);
      }
    },

    filterPerformances() {
      const items = filter(this.performances, (it) => {
        let matched = true;
        if (isNotEmpty(this.filters.innerDayValue)) {
          matched = matched && it.weekDay === this.filters.innerDayValue;
        }

        if (isNotEmpty(this.filters.innerTimeValue)) {
          matched =
            matched &&
            (it.startTime >= this.filters.innerTimeValue || it.fullDay);
        }

        if (isNotEmpty(this.filters.innerActValue)) {
          matched = matched && it.name === this.filters.innerActValue;
        }

        if (isNotEmpty(this.filters.innerLocatieValue)) {
          matched = matched && it.location === this.filters.innerLocatieValue;
        }

        if (isNotEmpty(this.filters.innerTypeValue)) {
          matched = matched && it.genre === this.filters.innerTypeValue;
        }

        if (this.filters.isAcitve) {
          let now = moment();
          matched =
            matched &&
            it.endTimeActual.isAfter(now) &&
            it.weekDay === now.weekDay;
        }

        return matched;
      });
      return items;
    },
  },

  watch: {
    $route: function (from, to) {
      const delay = from.name === to.name ? 0 : 500;
      this.scrollToElementIfNeeded({ delay });
    },
  },

  apollo: {
    $loadingKey: "loading",
    mosaics: {
      query: listAllMosaic.query,
      variables: listAllMosaic.variables(9),
      prefetch: () => listAllMosaic.variables(9),
      fetchPolicy: "cache-first",
      update(data) {
        let mosaics = map(data.queryMosaicContents, (it) => {
          let m = it.data;
          let mapped = {
            name: get(m, "name.iv"),
            image: get(m, "image.iv.0.url"),
            smallImage: get(m, "smallImage.iv.0.url"),
            rectangleImage: get(m, "rectangleImage.iv.0.url"),
            priority: get(m, "priority.iv"),
            schedule: null,
            showOverlay: get(m, "showGreyOverlay.iv"),
            link: null,
            text: null,
          };
          let relatedTheme = get(m, "relatedTheme.iv.0.data");

          // link mosaic to a theme
          if (isNotEmpty(relatedTheme)) {
            let performance = get(relatedTheme, "performances.iv.0.data");
            if (isNotEmpty(performance)) {
              mapped.image = get(performance, "bigImage.iv.0.url");
              mapped.smallImage = get(performance, "smallImage.iv.0.url");
              mapped.rectangleImage = get(
                performance,
                "rectangleImage.iv.0.url"
              );
            }

            let themeTime = moment(get(relatedTheme, "0.data.startTime.iv"));
            let fullDay = get(it, "0.data.fullDay.iv", false);

            mapped.schedule = {
              location: null,
              weekDay: themeTime.format("dddd").substring(0, 2),
              startTime: fullDay ? "HELE DAG" : themeTime.format("HH:mm"),
            };

            mapped.link = {
              name: "theme-id",
              params: { id: get(relatedTheme, "0.data.shortName.iv") },
            };
          } else {
            let relatedPerformance = get(m, "relatedPerformance.iv.0.data");
            if (isNotEmpty(relatedPerformance)) {
              mapped.image = get(relatedPerformance, "bigImage.iv.0.url");
              mapped.smallImage = get(
                relatedPerformance,
                "smallImage.iv.0.url"
              );
              mapped.rectangleImage = get(
                relatedPerformance,
                "rectangleImage.iv.0.url"
              );

              let schedule = get(relatedPerformance, "schedules.iv.0.data");
              let performanceTime = moment(get(schedule, "startTime.iv"));
              let fullDay = get(schedule, "fullDay.iv", false);
              mapped.schedule = {
                location: get(schedule, "location.iv.0.data.name.iv"),
                weekDay: performanceTime.format("dddd").substring(0, 2),
                startTime: fullDay
                  ? "HELE DAG"
                  : moment(get(schedule, "startTime.iv")).format("HH:mm"),
              };

              mapped.link = {
                name: "performance-id",
                params: { id: get(relatedPerformance, "shortName.iv") },
              };
            } else {
              mapped.text = marked(get(m, "blockContent.iv", ""));
              mapped.link = get(m, "link.iv", "");
            }
          }

          return mapped;
        });

        mosaics.splice(7, 0, {
          smallImage: require("@/assets/images/reuring-logo.svg"),
        });
        return mosaics;
      },
    },
    themes: {
      query: listAllThemes.query,
      variables: {
        top: 10,
      },
      prefetch: () => ({
        top: 10,
      }),
      fetchPolicy: "cache-first",
      update(data) {
        let themes = data.queryThemeContents.map(function (theme) {
          let themeTime = moment(get(theme, "data.startTime.iv"));

          let mapped = {
            themeId: theme.id,
            image: get(
              theme,
              "data.performances.iv[0].data.smallImage.iv.0.url",
              ""
            ),
            name: get(theme, "data.name.iv"),
            shortName: get(theme, "data.shortName.iv"),
            weekDay: themeTime.format("dddd"),
            startTime:
              theme.data.fullDay && theme.data.fullDay.iv
                ? "HELE DAG"
                : themeTime.format("HH:mm"),
            startTimeActual: themeTime,
            location: "",
            genre: "",
            intro: "",
          };

          if (isEmpty(mapped.image)) {
            mapped.image = get(theme, "data.smallImage.iv.0.url");
          }
          return mapped;
        });
        themes = sortBy(themes, "startTimeActual");
        return themes;
      },
    },
    performances: {
      query: listAllPerformances.query,
      fetchPolicy: "cache-first",
      prefetch: true,
      update(data) {
        let performances = map(data.queryPerformanceContents, (item) => {
          let mapped = {
            performanceId: item.id,
            image: get(item, "data.smallImage.iv.0.url"),
            name: get(item, "data.name.iv"),
            shortName: get(item, "data.shortName.iv"),
            genre: get(item, "data.genre.iv"),
          };

          let schedules = get(item, "data.schedules.iv");
          mapped.schedules = map(schedules, (it) => {
            let startTime = moment(get(it, "data.startTime.iv"));
            let endTime = moment(get(it, "data.endTime.iv"));
            let location = get(it, "data.location.iv.0.data.name.iv");
            let fullDay = get(it, "data.fullDay.iv", false);
            return {
              location: location,
              weekDay: startTime.format("dddd"),
              startTime: fullDay ? "HELE DAG" : startTime.format("HH:mm"),
              endTime: endTime.format("HH:mm"),
              startTimeActual: startTime,
              endTimeActual: endTime,
              fullDay: fullDay,
              intro: marked(get(it, "data.programIntro.iv", "")),
            };
          });
          return mapped;
        });
        performances = flatMap(performances, (it) =>
          map(it.schedules, (s) => ({
            performanceId: it.performanceId,
            image: it.image,
            name: it.name,
            shortName: it.shortName,
            genre: it.genre,
            location: s.location,
            weekDay: s.weekDay,
            startTime: s.startTime,
            startTimeActual: s.startTimeActual,
            endTimeActual: s.endTimeActual,
            endTime: s.endTime,
            fullDay: s.fullDay,
            intro: s.intro,
          }))
        );
        let now = moment();
        performances = sortBy(performances, "startTimeActual");
        return performances;
      },
    },
  },

  components: {
    MosaicGrid,
    ProgramList,
    ProgramFilters,
    MadeBy,
  },
};
</script>
