import gql from 'graphql-tag'

export const listAll = {
  query: gql`{
    queryThemeContents(top: 0)
    {
      id
      data
      {
        fullDay
        {
          iv
        }
        startTime
        {
          iv
        }
        name
        {
          iv
        }
        shortName {
          iv
        },
        smallImage {
          iv {
            url
          }
        },
        performances
        {
          iv
          {
            data
            {
              shortName {
                iv
              },
              smallImage
              {
                iv
                {
                  url
                }
              },
              bigImage{
                iv {
                  url
                }
              },
              rectangleImage {
                iv {
                  url
                }
              }
            }
          }
        }
      }
    }
  }`
}

export const findByName = {
  query: gql`query findThemeByName($filter: String!) {
    queryThemeContents(top:1, filter: $filter)
    {
        data
        {
          name
          {
            iv
          }
          intro
          {
            iv
          },
          fullDay
          {
            iv
          }
          startTime
          {
            iv
          }
          endTime
          {
            iv
          }
          performances
          {
            iv
            {
              data
              {
                smallImage
                {
                  iv
                  {
                    url
                  }
                },
                bigImage {
                  iv {
                    url
                  }
                },
                rectangleImage {
                  iv {
                    url
                  }
                },
                name{
                  iv
                },
                shortName {
                  iv
                },
                genre
                {
                  iv
                },
                schedules
                {
                  iv
                  {
                    data
                    {
                      startTime
                      {
                        iv
                      }
                      fullDay
                      {
                        iv
                      }
                      programIntro
                      {
                        iv
                      }
                      location
                      {
                        iv
                        {
                          data
                          {
                            name
                            {
                              iv
                            }
                            geolocation
                            {
                              iv
                            }
                            priority
                            {
                              iv
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`,

    variables (name) {
      let filter = ''
      if (name && name.length > 0) {
        let normalizedName = name ? name.toString().replace(/'/g, '\'\'') : ''
        filter = `data/ShortName/iv eq '${normalizedName}'`
      }
      return {
        filter
      }
    }
  }
