<template>
  <div class="poster">
    <div v-if="routeTo == null" class="block box">
      <slot />
    </div>
    <nuxt-link v-else-if="!isStringLink(routeTo)" class="block box" :to="routeTo" :class="{'hover-animation': hasHoverAnimation}">
      <slot name="images">
        <img src="~assets/images/Waylon.png" alt="Waylon and frends" class="poster-image">
      </slot>
      <div class="poster__info">
        <time class="holding-time uppercased"><slot name="holding-time"> </slot></time>
        <div class="theme-title uppercased"><slot name="theme-title"> </slot></div>
        <h2 class="name uppercased"><slot name="name"> </slot></h2>
      </div>
      <div class="overlayer" v-if="showOverlay"></div>
    </nuxt-link>
    <a v-else class="block box" :href="routeTo" :class="{'hover-animation': hasHoverAnimation}">
        <slot name="images">
          <img src="~assets/images/Waylon.png" alt="" class="poster-image">
        </slot>
        <div class="poster__info">
          <time class="holding-time uppercased"><slot name="holding-time"> </slot></time>
          <div class="theme-title uppercased"><slot name="theme-title"> </slot></div>
          <h2 class="name uppercased"><slot name="name"> </slot></h2>
        </div>
        <div class="overlayer" v-if="showOverlay"></div>
      </a>
  </div>
</template>

<script>
export default {
  name: 'image-box',
  props: {
    routeTo: {
      type: String | Object,
      default() {
        return null
      }
    },
    hasHoverAnimation: {
      type: Boolean,
      default: true
    },
    showOverlay: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    isStringLink(link) {
      return typeof link === 'string'
    }
  }
}
</script>
