import gql from 'graphql-tag'

export const findByShortName = {
  query: gql`query findPerformance($filter: String!) {
    queryPerformanceContents(top:1, filter: $filter) {
      data {
        name {
          iv
        },
        genre {
          iv
        },
        practicalInfo {
          iv
        },
        webLink {
          iv
        },
        facebookLink {
          iv
        },
        twitterLink {
          iv
        },
        instagramLink {
          iv
        },
        youtubeLink {
          iv
        },
        spotifyLink {
          iv
        },
        about {
          iv
        },
        bigImage {
          iv {
            url
          }
        },
        smallImage {
          iv {
            url
          }
        },
        moreImages {
          iv {
            url
          }
        },
        embeddedVideo {
          iv
        },
        relatedPerformances {
          iv {
           data {
            name {
              iv
            }
            shortName {
              iv
            }
            smallImage {
              iv {
                url
              }
            }
                schedules {
                  iv {
                    data {
                      startTime {
                        iv
                      }
                      fullDay
                      {
                        iv
                      }
                      location {
                        iv {
                          data {
                            name {
                              iv
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
          }
        }
        schedules {
          iv {
            data {
              startTime {
                iv
              },
              endTime {
                iv
              },
              fullDay {
                iv
              },
              location {
                iv {
                  data {
                    priority {
                      iv
                    }
                    name {
                      iv
                    },
                    geolocation {
                      iv
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`,
  variables (shortName) {
    let filter = ''
    if (shortName && shortName.length > 0) {
      let normalizedShortName = shortName ? shortName.toString().replace(/'/g, '\'\'') : ''
      filter = `data/ShortName/iv eq '${normalizedShortName}'`
    }
    return {
      filter
    }
  }
}

export const listAll = {
  query: gql`{
    queryPerformanceContents(top:0, orderby: "data/Name/iv") {
      id
      data {
        name {
          iv
        },
        genre {
          iv
        },
        shortName {
          iv
        },
        smallImage {
          iv {
            url
          }
        },
        schedules {
          iv {
            data {
              startTime {
                iv
              },
              endTime {
                iv
              },
              fullDay {
                iv
              },
              programIntro {
                iv
              },
              location {
                iv {
                  data {
                    name {
                      iv
                    },
                    geolocation {
                      iv
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`
}
