<template>
  <div class="poster-wrapper clearfix">
    <transition name="fade" mode="out-in">
      <div v-if="!isLoading">
        <div class="poster__item" v-for="(mosaicItem, key) in mosaics" :key="key">
          <image-box
            v-if="mosaicItem.link != null"
            :route-to="mosaicItem.link"
            :show-overlay="mosaicItem.showOverlay"
          >
            <img slot="images" :src="chooseImage(mosaicItem, key)" :alt="mosaicItem.name">
            <span slot="holding-time" class="holding-time" v-if="mosaicItem.schedule">{{ mosaicItem.schedule.weekDay }} {{ mosaicItem.schedule.startTime }}</span>
            <span slot="theme-title" v-if="mosaicItem.schedule">{{ mosaicItem.schedule.location }}</span>
            <div slot="theme-title" v-if="!mosaicItem.schedule" v-html="mosaicItem.text"></div>
            <span slot="name">{{ mosaicItem.name }}</span>
            <nuxt-link v-if="!isStringLink(mosaicItem.link)" slot="btn-link-more" class="btn-link-more" :to="mosaicItem.link">LEES MEER</nuxt-link>
            <a v-if="isStringLink(mosaicItem.link)" slot="btn-link-more" class="btn-link-more" :title="mosaicItem.name" :href="mosaicItem.link">LEES MEER</a>
          </image-box>
          <image-box v-else :show-overlay="mosaicItem.showOverlay">
            <img :src="chooseImage(mosaicItem, key)" alt="">
          </image-box>
        </div>
      </div>
      <div v-else>
        <div class="poster__item empty-loading" v-for="n in emptyGridCount" :key="n">
          <div class="poster">
            <div class="empty-box">
              <div class="radials"></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ImageBox from '@/components/ImageBox'
import { get } from 'lodash'

export default {
  name: 'mosaic-grid',

  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    emptyGridCount: {
      type: Number,
      default: 10
    },
    mosaics: {
      type: Array,
      default: function() {
        return [{
          name: '',
          image: '',
          smallImage,
          rectangleImage,
          showOverlay: true,
          priority: 0,
          schedule: {
            weekDay: '',
            startTime: '',
            location: ''
          },
          text: null,
          link: null
        }]
      }
    }
  },
  methods: {
    chooseImage(mosaicItem, index) {
      let pos = index % 10
      let img = mosaicItem.image
      switch (pos) {
        case 3, 4:
          img = mosaicItem.rectangleImage
          break
        case 1, 2, 6, 7:
          img = mosaicItem.smallImage
          break
      }

      return img
    },
    isStringLink(link) {
      return typeof link === 'string'
    }
  },
  components: {
    ImageBox
  }
}
</script>
