<template>
  <div>
    <div class="programma-list" v-for="(value, key) in items" :key="key">
      <h3 class="title uppercased">{{ key }}</h3>
      <div class="list-wrap">
        <nuxt-link tag="dl" :to="routeFor(item)" class="filter-list pointer-cursor" data-programma="programma-item" v-for="(item, i) in value" :key="i">
          <dt>{{ item.startTime }}</dt>
          <dd>
            <div class="list-info">
              <img class="images" :src="item.image" :alt="item.name" />
              <div>
                <h4 class="list-name">{{ item.name }}</h4>
                <p class="list-theme-title">{{ item.location }}
                  <span v-show="item.location">/</span>{{ item.genre }}
                </p>
                <p class="list-price" v-if="item.intro" v-html="item.intro">{{ item.intro }}</p>
              </div>
            </div>
          </dd>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'programma-list',
  props: {
    items: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    routeFor (item) {
      if (item.themeId) {
        return {
          name: 'theme-id',
          params: {
            id: item.shortName
          }
        }
      } else {
        return {
          name: 'performance-id',
          params: {
            id: item.shortName
          }
        }
      }
    }
  }
}
</script>
