<template>
  <div class="programma-filter">
    <h3 class="title">PROGRAMMA</h3>
    <div class="filed-item filter__item">
      <div class="flex-box">
        <a
          class="field-nu"
          :class="{active: value.isAcitve}"
          @click.prevent.stop="changeField('isAcitve', !value.isAcitve)"
        >
          <span>NU</span>
        </a>
        <div class="field-date">
          <app-select
            :items="weekDayOptions"
            :value="value.innerDayValue"
            @change="changeField('innerDayValue', $event)"
          />
        </div>
        <div
          class="field-time"
          v-if="false"
        >
          <app-select
            :items="timeOptions"
            :value="value.innerTimeValue"
            @change="changeField('innerTimeValue', $event)"
          />
        </div>
      </div>
    </div>
    <div class="filed-item  filter__item-large">
      <div class="field-act item-block">
        <app-select
          :items="nameOptions"
          :value="value.innerActValue"
          @change="changeField('innerActValue', $event)"
        />
      </div>
      <div class="field-locatie item-block">
        <app-select
          :items="locationOptions"
          :value="value.innerLocatieValue"
          @change="changeField('innerLocatieValue', $event)"
        />
      </div>
      <div class="field-type item-block">
        <div class="flex-box">
          <div class="field-genre">
            <app-select
              :items="genreOptions"
              :value="value.innerTypeValue"
              @change="changeField('innerTypeValue', $event)"
            />
          </div>
          <a
            class="reset-item"
            @click.prevent.stop="reset"
          ><i class="fa fa-refresh"></i></a>
        </div>
      </div>
    </div>
    <!--<div class="filed-item filter__item-0">
        <a class="reset-item" @click.prevent.stop="reset"><i class="fa fa-refresh"></i></a>
    </div>-->
  </div>
</template>

<script>
  export default {
    name: 'program-filters',

    props: {
      weekDayOptions: {
        type: Array,
        default: () => []
      },
      timeOptions: {
        type: Array,
        default: () => []
      },
      nameOptions: {
        type: Array,
        default: () => []
      },
      locationOptions: {
        type: Array,
        default: () => []
      },
      genreOptions: {
        type: Array,
        default: () => []
      },
      value: {
        type: Object,
        default: () => ({
          innerDayValue: '',
          innerTimeValue: '',
          innerActValue: '',
          innerLocatieValue: '',
          innerTypeValue: '',
          isAcitve: false
        })
      }
    },

    methods: {
      changeField (fieldName, newValue) {
        this.$emit('input', {
          ...this.value,
          [fieldName]: newValue
        })
      },

      reset () {
        this.$emit('input', {
          innerDayValue: '',
          innerTimeValue: '',
          innerActValue: '',
          innerLocatieValue: '',
          innerTypeValue: '',
          isAcitve: false
        })
      }
    }
  }
</script>
