import gql from 'graphql-tag'

export const listAll = {
  query: gql`query queryMosaics($top: Int!) {
    queryMosaicContents(top: $top, orderby: "data/Priority/iv") {
      data {
        name {
          iv
        }
        image {
          iv {
            url
          }
        },
        smallImage {
          iv {
            url
          }
        },
        rectangleImage {
          iv {
            url
          }
        },
        blockContent {
          iv,
        },
        link {
          iv
        },
        priority {
          iv
        }
        showGreyOverlay {
    	    iv
        }
        relatedPerformance {
          iv {
            id,
            data {
              shortName {
                iv
              },
              bigImage {
                iv {
                  url
                }
              },
              smallImage {
                iv {
                  url
                }
              },
              rectangleImage {
                iv {
                  url
                }
              },
              schedules {
                iv {
                  data {
                    startTime {
                      iv
                    }
                    fullDay{
                      iv
                    }
                    location {
                      iv {
                        data {
                          name {
                            iv
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        relatedTheme {
          iv {
            id,
            data {
              shortName {
                iv
              },
              startTime {
                iv
              },
              fullDay
              {
                iv
              },
              performances {
                iv {
                  data {
                    bigImage {
                      iv {
                        url
                      }
                    },
                    smallImage {
                      iv {
                        url
                      }
                    },
                    rectangleImage {
                      iv {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`,

  variables (top) {
    return {
      top
    }
  }
}
