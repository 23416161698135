<template>
  <div class="brand">
    <h4 class="title">REURING FESTIVAL WORDT MEDE MOGELIJK GEMAAKT DOOR:</h4>
    <div class="images flex-box">
      <div class="img-item" v-for="item in items" :key="item.name">
        <a :href="item.url" target="_blank">
          <img
            :src="item.image"
            :alt="item.name"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'made-by',
    data () {
      return {
        items: [
          { name: 'Purmerend', image: require('@/assets/images/upload/Sponsor_Purmerend.png'), url: 'https://www.purmerend.nl/' },
          { name: 'Vanthek', image: require('@/assets/images/upload/vantHek.png'), url: 'https://www.vanthek.nl/' },
          // { name: 'Vsbfonds', image: require('@/assets/images/upload/VSB.png'), url: 'https://www.vsbfonds.nl/' },
          // { name: 'rb2', image: require('@/assets/images/upload/sponsor/01_RB2.png'), url: 'https://www.rb2.nl/' },
          // { name: 'Rabo bank', image: require('@/assets/images/upload/sponsor/03_RABOBANK.png'), url: 'https://www.rabobank.nl/lokale-bank/waterland-en-omstreken/' },
          // { name: 'Rodi', image: require('@/assets/images/upload/sponsor/04_RODI.png'), url: 'https://www.rodi.nl/regio/purmerend/' },
          // { name: 'Purmaryn', image: require('@/assets/images/upload/sponsor/logo_Purmaryn.png'), url: 'https://depurmaryn.nl/' },
          // { name: 'Provincie Noord Holland', image: require('@/assets/images/upload/sponsor/logo_NH.png'), url: 'https://www.noord-holland.nl/' }
          // { name: 'VSB', image: require('@/assets/images/upload/sponsor/05_VSB.png'), url: 'https://www.vsbfonds.nl/' },
          // { name: 'ITSD', image: require('@/assets/images/upload/sponsor/06_ITSD.png'), url: 'https://www.itsd.nl/' }
        ]
      }
    }
  }
</script>
